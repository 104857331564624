<template>
  <div class="liveBroadcast">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'liveBroadcast'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane
              label="直播管理"
              name="first"
            ></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="直播来源">
                <el-input
                  v-model="formInline.sourceType"
                  placeholder="直播来源"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="直播状态">
                <el-select v-model="formInline.status" placeholder="直播状态" filterable clearable>
                  <el-option v-for="(item, key, index) in $common.liveStatusData" :key="index" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button
                class="mainBtn"
                @click="onSubmit"
                plain
                v-preventReClick
                >搜索</el-button
              >
              <el-button type="info" @click="resetEvent" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="gotoLive('add')" plain>接入直播</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
          >
            <el-table-column
            v-for="(item, index) in columnList"
            :key="index"
            :prop="item.prop"
            :label="item.label ? item.label : '/'"
            :width="item.width"
            align="center"
          >
            <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property] == null">/</span>
                <template v-else-if="scope.column.property === 'title' || scope.column.property == 'sourceType' || scope.column.property == 'teacherName'">
                  <el-tooltip
                    :content="scope.row[scope.column.property]"
                    placement="top"
                    effect="light"
                    popper-class="pubToop"
                  >
                    <div class="ellipsis">{{ scope.row[scope.column.property] || "/" }}</div>
                  </el-tooltip>
                </template>
                <template v-else-if="scope.column.property === 'status'">
                  <span>{{$common.liveStatusData[scope.row[scope.column.property]]}}</span>
                </template>
                <template v-else-if="scope.column.property === 'planStartTime'">
                  <span>{{
                    $moment(Number(scope.row[scope.column.property])).format(
                      " YYYY-MM-DD  HH:mm"
                    )
                  }}</span>
                </template>
                <span v-else>
                  {{ scope.row[scope.column.property] }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="170"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="gotoLive('edit', scope.row)">编辑</el-button>
                <el-button type="text" @click="gotoLive('details', scope.row)">直播详情</el-button>
                <el-button type="text" @click="deleteEvent(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
      </template>
    </el-scrollbar>

    <!-- 删除 -->
    <handle
      :isShow="isShowDel"
      :handleText="handleText"
      @handlesEvent="doEvent"
    >
      <div class="btn_area" slot="handle_btn">
        <el-button @click="doEvent">关闭</el-button>
        <el-button type="primary" @click="deleStu">确定</el-button>
      </div>
    </handle>

    <!-- 接入直播，直播详情 -->
    <live-opera ref="liveopera"></live-opera>
  </div>
</template>

<script>
import liveOpera from './liveOpera'
import Pagination from '../../Pagination'
import handle from '@/components/commonModule/handle'
export default {
  name: 'liveBroadcast',
  components: {
    Pagination,
    handle,
    liveOpera
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        page: 1,
        pageSize: 10,
        sourceType: null,
        status: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      columnList: [
        {
          prop: "title",
          label: "直播标题"
        },
        {
          prop: "sourceType",
          label: "直播来源"
        },
        {
          prop: "teacherName",
          label: "直播讲师"
        },
        {
          prop: "planStartTime",
          label: "直播预计开始时间"
        },
        {
          prop: "status",
          label: "直播状态"
        }
      ],
      isShowDel: false,
      handleText: '确定要删除该直播？'
    }
  },
  created() {
    this.getLiveInfoData()
  },
  methods: {
    handleKeydown(event) {
      if (!this.isShowDel&&!this.$refs.liveopera.isShow) {
        this.onSubmit()
      }
    },
    // 搜索事件
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getLiveInfoData();
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
    },
    // 获取直播管理表格数据
    getLiveInfoData() {
      this.$request.liveInfoData(this.formInline).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getLiveInfoData();
    },
    deleteEvent(row) {
      this.rowData = row
      this.doEvent()
    },
    doEvent() {
      this.isShowDel = !this.isShowDel
    },
    deleStu() {
      this.$request.delateLive({ id: this.rowData.id }).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '删除成功');
          this.doEvent()
          this.getLiveInfoData();
          this.rowData = {};
        }
      });
    },
    gotoLive(type, row = {}) {
      this.$refs.liveopera.showEvent(row, type)
    },
  },
}
</script>

<style lang='less'>
  .liveBroadcast {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
  }
</style>