<template>
  <div class="liveOpera">
    <dialogDiyNew
      :isShow="isShow"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div slot="dialogContent" class="liveOpera_opera">
        <el-form
          :model="formInline"
          class="liveOpera_form"
          label-width="136px"
          ref="liveInsert"
          :rules="rules"
          :disabled="diaType == 'details'"
        >
          <el-form-item label="直播标题" prop="title">
            <el-input
              v-model="formInline.title"
              placeholder="直播标题"
              clearable
              maxlength="25"
            ></el-input>
          </el-form-item>
          <el-form-item label="直播讲师" prop="teacherName">
            <el-input
              v-model="formInline.teacherName"
              placeholder="直播讲师"
              clearable
              maxlength="25"
            ></el-input>
          </el-form-item>
          <el-form-item label="讲师介绍" prop="teacherIntroduction">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入"
              v-model="formInline.teacherIntroduction"
              maxlength="255"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="直播状态" prop="status">
            <el-select v-model="formInline.status" placeholder="直播状态" filterable clearable>
              <el-option v-for="(item, key, index) in $common.liveStatusData" :key="index" :label="item" :value="key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="直播地址">
            <el-input
              v-model="formInline.pullLiveUrl"
              placeholder="直播地址"
              clearable
              maxlength="128"
            ></el-input>
          </el-form-item>
          <el-form-item label="直播来源">
            <el-input
              v-model="formInline.sourceType"
              placeholder="直播来源"
              clearable
              maxlength="25"
            ></el-input>
          </el-form-item>
          <el-form-item label="直播预计开始时间" prop="planStartTime">
            <el-date-picker
              v-model="formInline.planStartTime"
              type="datetime"
              clearable
              placeholder="直播预计开始时间"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="直播封面" prop="coverUrl">
            <div class="img-area">
              <div class="imgs" @click="getLocalPic" v-if="!formInline.coverUrl">
                <img src="@/assets/img/pic.png" alt="" />
                <span>选择图片</span>
              </div>
              <div class="show-area" v-else>
                <img class="showImgs" :src="formInline.coverUrl" alt="" />
                <span class="preview" @click="getLocalPic" v-if="diaType != 'details'">修改图片</span>
                <span class="preview" @click="watchEvent([formInline.coverUrl])"
                  >图片预览</span
                >
              </div>
              <div class="upload-tips">建议像素：249*140</div>
            </div>
          </el-form-item>
          <el-form-item label="直播简介" prop="about">
            <vueEditor :editorContent.sync="formInline.about"></vueEditor>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button @click="dialogDiyEvent">取消</el-button>
          <el-button type="primary" @click="enterEvent" v-if="diaType != 'details'">确认</el-button>
        </div>
      </div>
    </dialogDiyNew>

    <el-image-viewer
      v-if="showViewer"
      :on-close="watchEvent"
      :url-list="voucherUrlsPic"
    />
    <input
      style="display: none"
      type="file"
      @change="picChange()"
      ref="loadPic"
      accept="image/*"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer"
import dialogDiyNew from '@/components/commonModule/dialogDiyNew'
import vueEditor from '@/components/commonModule/vueEditor'
export default {
  name: 'liveOpera',
  components: {
    ElImageViewer,
    dialogDiyNew,
    vueEditor
  },
  data() {
    return {
      diaType: null,
      isShow: false,
      dialogDiyData: {
        title: null,
        sizeStyle: {width: '760px',height: '800px'}
      },
      formInline: {
        title: null,
        sourceType: null,
        teacherName: null,
        status: null,
        pullLiveUrl: null,
        planStartTime: null,
        coverUrl: null,
        about: null,
        teacherIntroduction: null
      },
      showViewer: false,
      voucherUrlsPic: [],
      rules: {
        title: [
          { required: true, message: "请输入直播标题", trigger: "blur" }
        ],
        teacherName: [
          { required: true, message: "请输入直播讲师", trigger: "blur" }
        ],
        teacherIntroduction: [
          { required: true, message: "请输入讲师介绍", trigger: "blur" }
        ],
        status: [
          { required: true, message: "请选择直播状态", trigger: ["blur", "change"] }
        ],
        planStartTime: [
          { required: true, message: "请选择直播预计开始时间", trigger: "blur" }
        ],
        coverUrl: [
          { required: true, message: "请上传直播封面", trigger: ["blur", "change"] }
        ],
        about: [
          { required: true, message: "请输入直播简介", trigger: "blur" }
        ]
      },
      rowData: {}
    }
  },
  methods: {
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
    },
    showEvent(obj, type) {
      this.rowData = obj
      this.diaType = type
      this.dialogDiyData.title = type == 'add' ? '接入直播' : type == 'edit' ? '编辑直播' : '直播详情'
      console.log(obj, type, 985211);
      if(type != 'add') this.getLiveDetails()
      this.dialogDiyEvent()
    },
    dialogDiyEvent() {
      this.resetEvent()
      this.isShow = !this.isShow
    },
    // 获取直播详情
    getLiveDetails() {
      this.$request.liveInfoDetails({id: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.formInline.title = res.data.data.title
          this.formInline.sourceType = res.data.data.sourceType
          this.formInline.teacherName = res.data.data.teacherName
          this.formInline.status = String(res.data.data.status)
          this.formInline.pullLiveUrl = res.data.data.pullLiveUrl
          this.formInline.planStartTime = res.data.data.planStartTime
          this.formInline.coverUrl = res.data.data.coverUrl
          this.formInline.about = res.data.data.about
          this.formInline.teacherIntroduction = res.data.data.teacherIntroduction
        }
      })
    },
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    picChange() {
      let inputDOM = this.$refs.loadPic;
      console.log(inputDOM, 888);
      let files = inputDOM.files[0];
      // let isFileType = files.type;
      // if(isFileType != 'image/png' || isFileType != 'image/jpeg') {
      //   this.Warn('上传图片只能是 PNG 或 JPG 格式!');
      //   return;
      // }
      let that = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        //加载图片获取图片真实宽度和高度
        var image = new Image();
        image.onload = function () {
          var width = image.width;
          var height = image.height;
          // if(width != 500 && height != 500) {
          //   that.Warn('上传图片的尺寸只能为500X500')
          //   return;
          // }
          that.upLoadEvent(files);
        };
        image.src = data;
      };
      reader.readAsDataURL(files);
    },
    async upLoadEvent(file) {
      let obj = await this.$uploadAli.imgFileUploadOss(file, 'img')
      // console.log(obj);
      if(obj.hasOwnProperty('excelUrl') && obj.excelUrl) {
        this.$set(this.formInline, 'coverUrl', obj.excelUrl)
        this.$refs['liveInsert'].validateField('coverUrl')
      }
    },
    watchEvent(urlArr) {
      this.showViewer = !this.showViewer;
      if (this.showViewer) {
        this.voucherUrlsPic = urlArr;
      }
    },
    enterEvent() {
      this.$refs['liveInsert'].validate((valid) => {
        if (valid) {
          if(this.diaType == 'add') {
            // 接入直播
            this.addLiveEvent()
          } else {
            // 编辑直播
            this.editLiveEvent()
          }
        } else {
          this.Warn('有必填项未填')
          return false;
        }
      });
    },
    addLiveEvent() {
      this.$request.insertLive(this.formInline).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.dialogDiyEvent()
          this.$parent.getLiveInfoData()
        }
      })
    },
    editLiveEvent() {
      let obj = JSON.parse(JSON.stringify(this.formInline))
      obj.id = this.rowData.id
      this.$request.updateLiveInfo(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.dialogDiyEvent()
          this.$parent.getLiveInfoData()
        }
      })
    }
  },
}
</script>

<style lang='less'>
  .liveOpera {
    .liveOpera_opera {
      padding: 20px 0;
      .liveOpera_form {
        width: 650px;
        margin: 0 auto;
        .img-area {
          .upload-tips {
            font-size: 12px;
            color: #939191;
          }
          .imgs {
            width: 140px;
            height: 80px;
            border-radius: 6px;
            border: 1px solid #c6d1db;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
            img {
              width: 35px;
            }
            span {
              color: #666666;
              font-size: 14px;
              display: inline-block;
              height: 20px;
              line-height: 20px;
            }
          }
          .show-area {
            // width: 140px;
            height: 80px;
            .showImgs {
              // width: 80px;
              height: 80px;
            }
            .preview {
              margin-left: 20px;
              cursor: pointer;
              &:hover {
                color: #1282ff;
              }
            }
          }
        }
      }
      .btn_area {
        text-align: center;
      }
    }
  }
</style>